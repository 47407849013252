import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import Content, {HTMLContent} from '../components/Content'
import * as _ from 'lodash'
import ContactFooter from "../components/ContactFooter";
import ContactForm from "../components/ContactForm";
import * as helper from "../utils";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { scrollToElement } from "../components/utils";

export const TreeSurveysLangingPageTemplate = ({title, subtitle, text, formName, image, cards, topSection, process, contentComponent}) => {
    const PageContent = contentComponent || Content

    return (
        <React.Fragment>
            <section className={'hero is-large flex-hero'}>
                <div className="hero-body margin-top-0"
                     style={{
                         backgroundImage: `url(${
                             !!image.childImageSharp
                                 ? image.childImageSharp.fluid.src
                                 : image
                         })`,
                         backgroundPosition: `center bottom`,
                         backgroundAttachment: `fixed`,
                         backgroundSize: 'cover',
                         backgroundRepeat: 'no-repeat',
                         display: 'flex'
                     }}>
                    <div className={'container d-flex'}>
                        <div className={'columns landing-page-b--hero-container'} style={{
                            // height: 'calc(100% - 127px)',
                            alignItems: 'center',
                        }}>
                          <div className={'column'}>
                            <div className={'columns is-align-items-center has-black-background'}
                                  style={{borderRadius: '0.75rem'}}>
                            <div style={{
                                lineHeight: '1',
                                opacity: '0.99',
                            }} className={'column pt-mobile-5 pb-mobile-4 mt-mobile-4 p-3'}>

                                <h1 className="h1 is-size-3-mobile is-size-1-tablet is-size-1-widescreen"
                                    style={{
                                        fontFamily: 'Museo',
                                        fontWeight: '500',
                                        color: 'white',
                                        lineHeight: '1.2',
                                        padding: '0.3rem',
                                        // maxWidth: '54vh',
                                        // margin: '0 auto',
                                        whiteSpace: 'pre-line'
                                    }}>
                                    {title}
                                </h1>
                                <h3 className="is-size-5-mobile is-size-5-tablet is-size-4-widescreen has-text-weight-semibold"
                                    style={{
                                        color: 'white',
                                        lineHeight: '1.2',
                                        padding: '0.3rem',
                                        maxWidth: '96%',
                                        // margin: '0 auto',
                                        letterSpacing: '0.5px'
                                    }}>
                                    {subtitle}
                                </h3>

                                <div className="is-size-6-mobile is-size-6-tablet is-size-6-widescreen"
                                     style={{
                                         color: 'white',
                                         lineHeight: '1.2',
                                         padding: '0.25rem',
                                         // margin: '0 auto',
                                         letterSpacing: '0.5px'
                                     }}>
                                    <div className={'hero-description-list'}>
                                        {helper.parse(text)}
                                    </div>
                                </div>

                            </div>
                              <div style={{
                                lineHeight: '1',
                                order: '1'
                              }} className={'column p-0 mb-mobile-4'}>
                                <div className={'card rounded-corners p-3 h-auto'} style={{
                                  backgroundColor: 'rgba(255,255,255,0.96)'
                                }}>
                                  <h3 id='contact' className={'font-primary is-ligna-font pt-1'}>Contact Us</h3>
                                  <hr className={'my-3'}/>
                                  <ContactForm name={formName} buttonText={'Get your free quote'}/>
                                </div>
                              </div>
                            </div>
                            <div className={'mb-8 columns testimonials-carousel'}>
                              <Carousel centerMode={true} showIndicators={false} showStatus={false} autoPlay={true}
                                        showArrows={true} showThumbs={false} infiniteLoop={true} emulateTouch={true}>
                                <Testimonial
                                  name={'Francois Ruiz'}
                                  description={'Homeowner'}
                                  stars={5}
                                  tagline={'Fantastic service'}
                                  text={'Fast, efficient and went over and above to help us. I would happily recommend and will certainly be using again in the future.'}
                                />
                                <Testimonial
                                  name={'Chris Bullard'}
                                  description={'New Homeowner'}
                                  stars={5}
                                  text={'Provided a detailed report in a very prompt manner to help with a house purchase.'}
                                />
                                <Testimonial
                                  name={'Nick Brod'}
                                  description={'Homeowner'}
                                  stars={5}
                                  tagline={'Excellent'}
                                  text={'Ben provides a personal service, an attention to detail, reliable and great value for money. Would definitely use Ligna again.'}
                                />
                              </Carousel>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
              <span className={'carousel-root'}></span>
            </section>

            <section className={'half-overlay-top is-hidden-mobile'}>
                <div className={'container'}>
                    <div className="columns">
                        {_.map(cards, service => {
                            return <div className="column">
                                <div className={'card no-padding white-bg rounded-corners'} style={{maxWidth: '500px'}}>
                                    <div className={'card-header has-padding white-bg float-icon pb-0'}>
                                        <div className={'icon-circle-container'}>
                                            <div className={'icon-circle primary-bg'}>
                                                <i className={service.icon + (' font-white')}></i>
                                            </div>
                                        </div>
                                        <h3 className="card-title font-primary has-text-centered">
                                            {helper.parse(service.title)}
                                        </h3>
                                        <p className="card-subtitle has-text-centered">{service.subtitle}</p>
                                    </div>
                                    <div className={'card-body pt-2'}>
                                        <p className={'card-first-para'}>{service.text}</p>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </section>
            <section className={'section dark-green-bg border-top-primary'}>
                {/*<h4 className={'has-text-centered font-primary'}>/ &nbsp;{topSection.title} &nbsp;/</h4>*/}
                <div className={'centered-text-container line-left font-white'}>
                    <p className={'text-first-para font-white'}>{helper.parse(topSection.firstPara)}</p>
                    <p className={'text'}>{helper.parse(topSection.text)}</p>
                </div>
            </section>

            {_.map(process.stages, (stage, index) => {
                return <section className={'section light-green-bg border-top-primary'}>
                    <div className={'container'}>
                        {index === 0 ?
                            <h4 className={'has-text-centered font-primary'}>/ &nbsp;{process.title} &nbsp;/</h4> : ''}
                        <div className={'columns mobile-margin-bottom my-4 is-centered'}>
                            {/*<div className={'column is-3 d-flex'}>*/}
                            {/*    <div className={'stage-title-container my-auto'}>*/}
                            {/*        <h3 className={'font-primary'}>{stage.title}</h3>*/}
                            {/*        <h5 className={'font-primary'}>{stage.subtitle}</h5>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {_.map(stage.children, child => {
                                return <div className="column is-one-third-fullhd is-one-third-desktop is-two-fifths-tablet">
                                    <div className={'card no-padding white-bg rounded-corners'}>
                                        <div className={'card-header has-padding primary-bg float-icon'}>
                                            <div className={'icon-circle-container'}>
                                                <div className={'icon-circle border-primary'}>
                                                    <i className={child.icon}></i>
                                                </div>
                                            </div>
                                            <h3 className="card-title font-white has-text-centered px-3">
                                                {helper.parse(child.title)}
                                            </h3>
                                            {/*<p className="card-subtitle has-text-centered">{child.subtitle}</p>*/}
                                        </div>
                                        <div className={'card-body'}>
                                            {/*<p className={'card-first-para'}>{child.tagline}</p>*/}
                                            <p>{child.text}</p>
                                        </div>
                                      <div className={'card-footer'}>
                                        <a className={'font-mint'} onClick={_=>scrollToElement('#contact')}>Get quote</a>
                                      </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </section>
            })}
            <ContactFooter/>
        </React.Fragment>
    )
}

const Testimonial = ({name, description, text, stars = 0, tagline = null}) => {
  return <div className={'w-100 d-flex is-justify-content-center'}>
    <div className={'testimonial-container'}>
      <div className={'testimonial'}>
        <div className={'d-flex top-info'}>
          <div>
            <h5>{name}</h5>
            <h6>{description}</h6>
          </div>
          <div className={'font-primary'}>
            {[...Array(stars)].map(_ => <i className="fas fa-star"></i>)}
          </div>
        </div>
        {tagline && <b>{tagline}</b>}
        <p>
          {text}
        </p>
      </div>
    </div>
  </div>
}

TreeSurveysLangingPageTemplate.propTypes = {
    // title: PropTypes.string.isRequired,
    // content: PropTypes.string,
    // contentComponent: PropTypes.func,
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
}

const TreeSurveysLangingPage = ({data}) => {
    const {frontmatter} = data.markdownRemark

    return (
        <Layout phone={'01284 598013'} email={'team@lignaconsultancy.co.uk'}>
            <TreeSurveysLangingPageTemplate
                contentComponent={HTMLContent}
                title={frontmatter.title}
                subtitle={frontmatter.subtitle}
                text={frontmatter.text}
                formName={frontmatter.formName}
                image={frontmatter.image}
                cards={frontmatter.cards}
                topSection={frontmatter.topSection}
                process={frontmatter.process}
            />
        </Layout>
    )
}

TreeSurveysLangingPage.propTypes = {
    data: PropTypes.object.isRequired,
}

export default TreeSurveysLangingPage

export const TreeSurveysLangingPageQuery = graphql`
  query TreeSurveysLangingPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subtitle
        text
        formName
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        cards {
            title
            icon
            text
        }
        topSection {
            title
            firstPara
            text
        }
        process {
            title
            stages {
                title
                subtitle
                children {
                    title
                    subtitle
                    icon
                    text
                    link
                }
            }
        }
      }
    }
  }
`
